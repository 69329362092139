import React from "react";
import { useSelector } from "react-redux";
import AppliedJobList from "../AppliedJobList/AppliedJobList";
import BulkApplyList from "../BulkApplyList/BulkApplyList";
import { CloseButton, HeaderStyled } from "../styles";
import {
  ArrowDownIcon,
  CloseLink,
  CollapsibleContainer,
  SecondaryHeaderContainer,
  SecondaryWrapper,
} from "./styles";

export default function SecondaryBulkApply(props) {
  const {
    dialog,
    selectedJobs,
    showList,
    setShowList,
    setSelectedJobs,
    recommendedJobs,
    jobAlert,
  } = props;

  const jobs = jobAlert ?? useSelector((state) => state?.jobs?.bulkAppliedJobs);
  const fetchingRecommendedJobs = useSelector(
    (state) => state?.jobs.fetchingRecommendedJobs
  );

  const jobAlertApplyExist = jobAlert?.length > 0;

  function handleClose() {
    setShowList(false);
  }

  function handleToggleList() {
    setShowList(!showList);
  }

  const headerText = jobAlertApplyExist
    ? "All Recommended Jobs from Job Alert"
    : `You've applied to ${jobs?.length} jobs.` +
      (showList ? "" : " Click to view all");

  return (
    <SecondaryWrapper
      open={showList}
      dialog={dialog}
      jobAlert={jobAlertApplyExist}
    >
      <SecondaryHeaderContainer
        open={showList}
        dialog={dialog}
        jobAlert={jobAlertApplyExist}
        onClick={handleToggleList}
      >
        <HeaderStyled size={"secondary"} jobAlert={jobAlertApplyExist}>
          {headerText}
        </HeaderStyled>
        <CloseButton onClick={handleToggleList}>
          <ArrowDownIcon open={showList} />
        </CloseButton>
      </SecondaryHeaderContainer>
      <CollapsibleContainer dialog={dialog} in={showList}>
        <AppliedJobList jobs={jobs} />
        <CloseLink onClick={handleClose}>Close List</CloseLink>
      </CollapsibleContainer>
      {dialog ? (
        <BulkApplyList
          dialog={dialog}
          secondaryBulk={true}
          showList={showList}
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
          fetchingJobs={fetchingRecommendedJobs}
          jobs={recommendedJobs}
        />
      ) : null}
    </SecondaryWrapper>
  );
}
