import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

export const BulkJobWrapper = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  width: "100%",
  margin: props?.$dialog ? "0 0 1rem" : "0",
}));

export const HeaderContainer = styling("div")((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: props?.$dialog ? "1rem 1.5rem 0" : "0",

  "@media (max-width: 640px)": {
    padding: props?.$dialog ? "0.5rem 0.75rem 0" : "0.5rem 1rem 0",
  },
}));

export const CardListContainer = styling("div")((props) => ({
  display: "flex",
  gap: "1rem",
  overflowX: "auto",

  maxHeight: "470px",
  height: "470px",
  padding: props.$noResult ? "1rem 1rem 0" : "1rem 0.125rem 0.75rem",

  margin: "0",
  scrollBehavior: "smooth",
  overflowY: "hidden",

  transition: "all 0.35s ease-in-out",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    height: 4,
    width: 4,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
    margin: props?.$dialog ? "0 1.5rem" : "0 0.125rem",
    "@media (max-width: 640px)": {
      margin: props?.$dialog ? "0 1rem" : "0 0.5rem",
    },
  },

  ...(props?.$dialog
    ? {
        padding: "0.75rem 1.5rem 1rem",
        ...(props?.$secondaryBulk
          ? props?.$showList
            ? {
                padding: "0.75rem 0 0.75rem 1.5rem",
                maxHeight: "171px",
              }
            : {
                maxHeight: "384px",
              }
          : {
              maxHeight: "467px",
            }),
      }
    : {
        padding: "0.75rem 0.125rem",
        ...(props?.$secondaryBulk
          ? props?.$showList
            ? {
                maxHeight: "176px",
              }
            : {
                maxHeight: "376px",
              }
          : {
              maxHeight: "400px",
            }),
      }),

  "@media (max-width: 640px)": {
    gap: "0.5rem",
    padding: props.$noResult ? "0.5rem 0.5rem 0" : "0.375rem 1rem ",
    margin: "0",
    scrollSnapType: "x mandatory",

    ...(props?.$dialog
      ? {
          padding: "0.375rem 0.75rem",
          ...(props?.$secondaryBulk
            ? props?.$showList
              ? {
                  padding: "0.75rem",
                  maxHeight: "171px",
                }
              : {
                  maxHeight: "370px",
                }
            : {
                maxHeight: "412px",
              }),
        }
      : {
          padding: "0.5rem 1rem",
          ...(props?.$secondaryBulk
            ? props?.$showList
              ? {
                  padding: "0.75rem",
                  maxHeight: "171px",
                }
              : {
                  maxHeight: "370px",
                }
            : {
                maxHeight: "400px",
              }),
        }),

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const ScrollIndicatorContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  padding: "0.125rem 0.5rem 0.5rem",
});

export const ScrollIndicator = styling("div")((props) => ({
  border: "1px solid" + Color.darkPurple,
  borderRadius: "6.25rem",
  minWidth: "5px",
  minHeight: "5px",

  ...(props.selected && {
    backgroundColor: Color.darkPurple,
    minWidth: "10px",
    minHeight: "10px",
  }),
}));

export const SelectAllButton = styling(ButtonBase)((props) => ({
  color: Color.hiredlyPurple,

  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.1px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
  },
}));
