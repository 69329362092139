import { useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MobileAppAd from "../MobileAppAd/MobileAppAd";
import SimilarJobCard from "../SimilarJobCard/SimilarJobCard";
import {
  BoxContainer,
  CheckboxHover,
  CheckboxIcon,
  CheckboxStyled,
  CheckboxWrapper,
  CheckedBox,
  HeaderStyled,
  UncheckedBox,
} from "../styles";
import {
  BulkJobWrapper,
  CardListContainer,
  HeaderContainer,
  ScrollIndicator,
  ScrollIndicatorContainer,
  SelectAllButton,
} from "./styles";

export default function BulkApplyList(props) {
  const {
    dialog,
    showList,
    secondaryBulk,
    selectedJobs,
    setSelectedJobs,
    fetchingJobs,
    jobs,
    jobAlertPage,
  } = props;

  const isMobile = useMediaQuery("(max-width: 640px)");

  const smallCard = showList && secondaryBulk;

  const [hover, setHover] = useState(false);
  const [inView, setInView] = useState(null);

  function checkedJob(job) {
    return selectedJobs.map((job) => job.id).includes(job?.id);
  }

  const checkedJobs = selectedJobs?.length > 0;

  const headerText = jobAlertPage
    ? "Apply to These Selected Jobs?"
    : "Similar jobs for you";

  const handleSelectText = useMemo(() => {
    if (checkedJobs) return "Deselect All";
    return "Select All";
  }, [checkedJobs]);

  function handleSelectAllJobs() {
    setSelectedJobs(jobs.map((job) => job));
  }

  function handleDeselectAllJobs() {
    setSelectedJobs([]);
  }

  function handleSelectFunction() {
    if (checkedJobs) {
      handleDeselectAllJobs();
    } else {
      handleSelectAllJobs();
    }
  }

  function handleSelectJob(job) {
    if (selectedJobs.map((job) => job.id).includes(job?.id)) {
      setSelectedJobs(
        selectedJobs.filter((selectedJob) => selectedJob.id !== job?.id)
      );
    } else {
      setSelectedJobs([...selectedJobs, job]);
    }
  }

  useEffect(() => {
    handleSelectAllJobs();
  }, [jobs, secondaryBulk]);

  return (
    <BulkJobWrapper $dialog={dialog}>
      {jobs?.length > 0 || fetchingJobs ? (
        <HeaderContainer $dialog={dialog}>
          <HeaderStyled>{headerText}</HeaderStyled>
          <SelectAllButton onClick={handleSelectFunction}>
            {handleSelectText}
            <CheckboxWrapper
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <CheckboxStyled
                icon={<UncheckedBox size={"small"} />}
                checked={checkedJobs}
                checkedIcon={
                  <CheckedBox size={"small"}>
                    <CheckboxIcon size={"small"} />
                  </CheckedBox>
                }
              />
              <CheckboxHover>
                <CheckboxIcon hover={hover} size={"small"} />
              </CheckboxHover>
            </CheckboxWrapper>
          </SelectAllButton>
        </HeaderContainer>
      ) : null}
      <BoxContainer flexDirection={"column"} height={"100%"}>
        <CardListContainer
          $dialog={dialog}
          $secondaryBulk={secondaryBulk}
          $showList={showList}
          $noResult={jobs?.length === 0 && !fetchingJobs}
        >
          {fetchingJobs ? null : jobs?.length > 0 ? (
            jobs.map((job, index) => {
              return (
                <SimilarJobCard
                  key={index}
                  index={index}
                  job={job}
                  smallCard={smallCard}
                  setInView={setInView}
                  checkedJob={checkedJob}
                  handleSelectJob={handleSelectJob}
                  dialog={dialog}
                />
              );
            })
          ) : (
            <MobileAppAd />
          )}
        </CardListContainer>

        {isMobile && (
          <ScrollIndicatorContainer>
            {Array.from({ length: jobs?.length }).map((_, index) => {
              return (
                <ScrollIndicator key={index} selected={inView === index} />
              );
            })}
          </ScrollIndicatorContainer>
        )}
      </BoxContainer>
    </BulkJobWrapper>
  );
}
