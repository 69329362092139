import { Grid, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateBulkApplyState,
  updatePostApplyDialog,
} from "../../../redux/actions/dialog_action";
import {
  bulkApplyJobs,
  clearBulkAppliedJobs,
  getPostApplyRecommendedJobs,
  triggerJobReload,
} from "../../../redux/actions/job_action";
import AppliedJobList from "./AppliedJobList/AppliedJobList";
import BulkApplyList from "./BulkApplyList/BulkApplyList";
import MobileAppAd from "./MobileAppAd/MobileAppAd";
import SecondaryBulkApply from "./SecondaryBulkApply/SecondaryBulkApply";
import {
  AppliedButtonStyled,
  AppliedJobContainer,
  AppliedJobWrapper,
  BoxContainer,
  ButtonStyled,
  CheckIcon,
  CloseButton,
  CloseIcon,
  ContentText,
  DialogContainer,
  DialogContent,
  DialogFooter,
  DialogHeader,
  HeaderStyled,
  LinkStyled,
  TextStyled,
  WarnIcon,
} from "./styles";

export default function PostApplyDialog(props) {
  const { page, origin } = props;
  const isMobile = useMediaQuery("(max-width: 640px)");

  const router = useRouter();
  const dispatch = useDispatch();
  const posthog = usePostHog();

  let originUrl = "";

  if (typeof window !== "undefined") {
    originUrl = localStorage?.getItem("apply_origin") ?? "";
  }

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [showList, setShowList] = useState(false);

  const userResume = useSelector((state) => state?.user?.user?.state);

  const {
    status: showDialog,
    scraped: isScraped,
    jobGlobalId,
  } = useSelector((state) => state.dialog.showPostApplyDialog);

  const bulkApplyState = useSelector((state) => state?.dialog?.bulkApplyState);
  const applyingJobs = useSelector((state) => state?.jobs?.applyingJobs);
  const bulkAppliedJobs = useSelector((state) => state?.jobs?.bulkAppliedJobs);
  const jobReload = useSelector((state) => state?.jobs?.jobReload);
  const recommendedJobs = useSelector((state) => state?.jobs?.recommendedJobs);
  const fetchingRecommendedJobs = useSelector(
    (state) => state?.jobs.fetchingRecommendedJobs
  );

  const selectedJobsLength = selectedJobs?.length ?? 0;
  const bulkApplyJobsStatus =
    bulkAppliedJobs?.length > 0 && bulkApplyState === "applied";

  const DialogHeaderIcon = useMemo(() => {
    if (!showDialog) return "";
    if ((isScraped && !bulkApplyJobsStatus) || userResume === "in_review")
      return <WarnIcon />;
    if (userResume === "approved") return <CheckIcon />;
    return <WarnIcon />;
  }, [isScraped, userResume, showDialog, bulkApplyJobsStatus]);

  const DialogHeaderTitle = useMemo(() => {
    if (!showDialog) return "";
    if (isScraped && !bulkApplyJobsStatus) return "Proceed in New Tab";
    if (userResume === "approved") return "Application successful";
    return "Application In Progress";
  }, [isScraped, userResume, showDialog, bulkApplyJobsStatus]);

  const DialogHeaderSubtitle = useMemo(() => {
    if (isScraped) {
      return "Continue your application in the new tab, in the meantime apply to other similar jobs!";
    }

    const redirectLink = (
      <LinkStyled
        onClick={handleViewJobApplicationHistory}
        textDecoration="underline"
      >
        Job Application Status
      </LinkStyled>
    );

    const subtitleCopy =
      userResume === "approved"
        ? "Your application will be submitted in 30 minutes; review or undo it in your "
        : "Your application will be submitted after resume approval; review or undo in ";

    return (
      <>
        {subtitleCopy}
        {redirectLink}
      </>
    );
  }, [isScraped, userResume]);

  const ApplyButtonText = `Apply to ${
    selectedJobsLength === 6 ? "All" : "Selected"
  } (${selectedJobsLength})`;

  const disableApplyButton = selectedJobsLength === 0 || applyingJobs;

  function handleCloseDialog() {
    setSelectedJobs([]);
    dispatch(clearBulkAppliedJobs());
    dispatch(
      updatePostApplyDialog({
        status: false,
        scraped: false,
        jobGlobalId: null,
      })
    );
  }

  function handleCloseAndRedirect() {
    if (bulkApplyJobsStatus) {
      dispatch(triggerJobReload(!jobReload));
    }
    handleCloseDialog();
    dispatch(updateBulkApplyState("post-apply"));

    if (router?.pathname?.includes("/apply")) {
      if (originUrl) {
        router?.push(originUrl);
        localStorage?.removeItem("apply_origin");
      } else {
        router?.push("/jobs");
      }
    }
  }

  function handleViewAllJobs() {
    if (page === "job-list") {
      return handleCloseAndRedirect();
    }
    handleCloseDialog();
    router.push("/jobs");
  }

  function handleApplyBulkJob(jobs) {
    const jobIds = jobs.map((job) => job.id);

    const params = {
      jobIds: jobIds,
    };
    dispatch(bulkApplyJobs(params)).then((res) => {
      if (res.success) {
        posthog.capture(
          //posthog tracking (Apply Jobs button) [JSW-3026]
          "job_bulk_apply",
          {
            from_job: jobGlobalId,
            job_discovery: "post_apply_rec",
            num_jobs_apply: jobs.length,
            job_ids: jobIds,
          }
        );

        sendTrackingEvent(
          //tracking (Apply Jobs button) [JSW-3026]
          {
            event: "CE_job_bulk_apply",
            from_job: jobGlobalId,
            job_discovery: "post_apply_rec",
            num_jobs_apply: jobs.length,
            job_ids: jobIds,
          }
        );
        if (bulkApplyState === "secondary-bulk") {
          dispatch(updateBulkApplyState("applied"));
        } else {
          const params = {
            globalId: jobGlobalId,
            first: 6,
          };

          dispatch(getPostApplyRecommendedJobs(params));
          dispatch(updateBulkApplyState("secondary-bulk"));
        }
      }
    });
  }

  function handleViewJobApplicationHistory() {
    window.open("/profile#job-application-history/?pending");
  }

  function handleReviewJobsApply() {
    handleCloseDialog();
    if (!router.pathname.includes("/apply")) {
      router.push("/profile#job-application-history/?pending");
    } else {
      router.push("/profile#job-application-history/?pending");
    }
  }

  useEffect(() => {
    if (jobGlobalId) {
      const params = {
        globalId: jobGlobalId,
        first: 6,
      };

      dispatch(getPostApplyRecommendedJobs(params));
    }
  }, [jobGlobalId]);

  return (
    <DialogContainer open={showDialog} onClose={handleCloseAndRedirect}>

      {origin != "Applied" &&
        <DialogHeader>
          <BoxContainer alignItems={"center"} justifyContent={"space-between"}>
            <BoxContainer gap={"0.5rem"} alignItems={"center"}>
              {DialogHeaderIcon}
              <TextStyled
                fontSize={isMobile ? "0.875rem" : "1.25rem"}
                fontWeight={"700"}
                lineHeight={"24px"}
              >
                {DialogHeaderTitle}
              </TextStyled>
            </BoxContainer>
            <CloseButton onClick={handleCloseAndRedirect}>
              <CloseIcon />
            </CloseButton>
          </BoxContainer>
          <ContentText>{DialogHeaderSubtitle} </ContentText>
        </DialogHeader>
      }

      <DialogContent>
        {bulkApplyState === "post-apply" ? (
          <BulkApplyList
            dialog={true}
            secondaryBulk={bulkApplyState === "secondary-bulk"}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            fetchingJobs={fetchingRecommendedJobs}
            jobs={recommendedJobs}
          />
        ) : bulkApplyState === "secondary-bulk" ? (
          <SecondaryBulkApply
            dialog={true}
            showList={showList}
            setShowList={setShowList}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            recommendedJobs={recommendedJobs}
          />
        ) : bulkApplyJobsStatus ? (
          <AppliedJobWrapper>
            <AppliedJobContainer>
              <HeaderStyled>
                You’ve applied to {bulkAppliedJobs?.length} jobs.
              </HeaderStyled>
              <AppliedJobList jobs={bulkAppliedJobs} />
            </AppliedJobContainer>
            <MobileAppAd />
          </AppliedJobWrapper>
        ) : null}
      </DialogContent>

      {origin == "Applied" ? (
        <Grid>
          {bulkApplyJobsStatus ? (
            <DialogFooter status={bulkApplyJobsStatus}>
              <AppliedButtonStyled onClick={handleReviewJobsApply}>
                Review Application
              </AppliedButtonStyled>
              <AppliedButtonStyled
                onClick={handleCloseAndRedirect}
                variant={"filled"}
                black
              >
                Done
              </AppliedButtonStyled>
            </DialogFooter>
          ) : (
            <DialogFooter origin={origin} status={bulkApplyJobsStatus}>
              <AppliedButtonStyled variant={"text"} onClick={handleCloseAndRedirect}>
                Back
              </AppliedButtonStyled>
              {recommendedJobs.length > 0 && (
                <AppliedButtonStyled
                  onClick={() => handleApplyBulkJob(selectedJobs)}
                  variant={"filled"}
                  disabled={disableApplyButton}
                >
                  Apply to Selected {`(${selectedJobsLength})`}
                </AppliedButtonStyled>
              )}
            </DialogFooter>
          )}
        </Grid>
      ) : (
        <Grid>
          <DialogFooter>
            {bulkApplyJobsStatus ? (
              <>
                <ButtonStyled onClick={handleReviewJobsApply}>
                  Review Application
                </ButtonStyled>
                <ButtonStyled
                  onClick={handleCloseAndRedirect}
                  variant={"filled"}
                  black
                >
                  Done
                </ButtonStyled>
              </>
            ) : (
              <>
                <ButtonStyled onClick={handleViewAllJobs}>
                  View All Jobs
                </ButtonStyled>
                {recommendedJobs.length > 0 && (
                  <ButtonStyled
                    onClick={() => handleApplyBulkJob(selectedJobs)}
                    variant={"filled"}
                    disabled={disableApplyButton}
                  >
                    {ApplyButtonText}
                  </ButtonStyled>
                )}
              </>
            )}
          </DialogFooter>
        </Grid>
      )}
    </DialogContainer>
  );
}
