import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";

export const CardWrapper = styling("div")((props) => ({
  backgroundColor: "rgba(247, 247, 255, 1)",
  border: props.selected
    ? "1px solid" + Color.hiredlyPurple
    : "1px solid" + Color.secondaryGrey,
  borderRadius: "0.75rem",
  boxShadow: props.selected
    ? "0px 0px 0px 1.5px " + Color.hiredlyPurple
    : "none",

  display: "flex",
  flex: "0 0 auto",
  flexDirection: "column",
  alignItems: "center",

  maxWidth: "248px",
  width: "100%",
  overflow: "clip",
  scrollSnapAlign: "center",

  transition: "all 0.35s ease-in-out",

  "&:hover": {
    border: "1px solid" + Color.hiredlyPurple,
    boxShadow: "none",
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    maxWidth: props?.$dialog ? "268px" : "296px",
  },
}));

export const TitleContainer = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "0.25rem",

  width: "100%",
  padding: "0.625rem",
}));

export const DescriptionContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  height: "100%",
  width: "100%",
  padding: "0.25rem 0.375rem 0.25rem 0.625rem",
  margin: "0 0.5rem 0 0",
  overflowY: "auto",
  scrollBehavior: "smooth",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: 4,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
  },
});

export const ChipContainer = styling("div")({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: "0.4375rem",
  width: "100%",
});

export const JobTitleStyled = styling(Typography)({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",

  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "24px",
  textOverflow: "ellipsis",

  overflow: "hidden",

  "@media (max-width: 640px)": {
    fontSize: "1rem",
  },
});

export const CompanyNameStyled = styling(Typography)({
  fontSize: "0.625rem",
  lineHeight: "20px",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  width: "9.5rem",
  overflow: "hidden",
});

export const JobSummaryContainer = styling("div")((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: Color.white,
  border: "1px solid" + Color.lightGrey,
  borderRadius: "0.375rem",

  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "20px",

  padding: "0.5rem 0.625rem",

  ...(props?.type === "skills" && {
    height: "100%",
    background: "#F7FFFA",
  }),

  ...(props?.type === "benefits" && {
    height: "100%",
    background: "#FFFEF7",
  }),
}));

export const JobSummaryText = styling(Typography)({
  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "20px",
});

export const JobSummaryTextBold = styling(Typography)({
  color: "rgba(0, 0, 0, 0.6)",
  borderBottom: "1px solid" + Color.lightGrey,
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: "20px",
  paddingBottom: "0.375rem",
  marginBottom: "0.375rem",
});

export const ShowMoreText = styling("span")({
  fontWeight: "700",
  cursor: "pointer",
});

export const MoreDetailButton = styling("a")((props) => ({
  color: Color.hiredlyPurple,
  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "24px",
  textDecoration: "none",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  padding: "1rem 0",

  "&:hover": {
    PointerEvents: "cursor",
  },

  "@media (max-width: 640px)": {
    padding: "0.625rem 1rem",
  },
}));

export const DetailChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.3125rem",
  padding: "0 0.375rem",

  ...(props.type === "jobType" && {
    color: Color.blue,
    backgroundColor: "rgba(33, 61, 181, 0.14)",
  }),
  ...(props.type === "state" && {
    color: "rgba(129, 155, 72, 1)",
    backgroundColor: "rgba(129, 155, 72, 0.14)",
  }),
  ...(props.type === "salary" && {
    color: "rgba(155, 72, 72, 1)",
    backgroundColor: "rgba(155, 72, 72, 0.14)",
  }),
}));

export const HeaderImage = styling("img")((props) => ({
  backgroundColor: Color.white,
  objectFit: props?.placeholder ? "cover" : "contain",

  maxHeight: "88px",
  minHeight: "88px",
  width: "100%",
  padding: props?.placeholder ? "0" : "0.5rem",
}));

export const CompanyImage = styling("img")({
  objectFit: "contain",

  backgroundColor: Color.white,
  border: "0.5px solid rgba(0, 0, 0, 0.38)",
  borderRadius: "0.25rem",

  height: "28px",
  width: "28px",
});
