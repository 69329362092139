import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

export const AppliedJobListWrapper = styling("div")((props) => ({
  backgroundColor: Color.backgroundGrey,
  borderRadius: "0.5rem",
  width: "100%",

  ...(props?.$jobAlert && {
    backgroundColor: Color.white,
  }),

  "@media (max-width: 640px)": {
    paddingRight: "0.375rem",
  },
}));

export const AppliedJobListContainer = styling("ul")((props) => ({
  maxHeight: "380px",
  width: "100%",
  padding: "0.625rem 0.5rem 0.625rem 1.625rem",
  margin: "0",
  overflowY: "auto",

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: 4,
    overflow: "auto",
    paddingRight: "10px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 64,
    backgroundColor: Color.hiredlyPurple,
    overflow: "auto",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 64,
    backgroundColor: Color.grey,
    margin: "10px 0",
  },

  "@media (max-width: 1024px)": {
    ...(props?.$secondaryBulk ||
      (!props?.$dialog && {
        maxHeight: "300px",
        overflowY: "auto",
      })),
  },

  "@media (max-width: 640px)": {
    padding: "0.5rem 0.5rem 0.5rem 1.875rem",

    ...(props?.$secondaryBulk ||
      (!props?.$dialog && {
        maxHeight: "155px",
        overflowY: "auto",
      })),
  },
}));

export const AppliedJobLink = styling("a")({
  color: Color.hiredlyPurple,
  cursor: "pointer",
  textDecoration: "none",
});

export const AppliedJobTextContainer = styling("li")({
  whiteSpace: "wrap",
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    whiteSpace: "wrap",
    lineHeight: "20px",
  },
});

export const AppliedJobText = styling("span")({
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const AppliedCompanyText = styling("span")({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "1rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});
