import { useMediaQuery } from "@mui/material";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { decode } from "html-entities";
import posthog from "posthog-js";
import React, { useEffect, useMemo, useState } from "react";
import * as config from "../../../../config/config";
import { formatSalary } from "../../../../helpers/conditional_rendering";
import { getCompanyLogoImage } from "../../../../helpers/data_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  BoxContainer,
  CheckboxHover,
  CheckboxIcon,
  CheckboxStyled,
  CheckboxWrapper,
  CheckedBox,
  UncheckedBox,
} from "../styles";
import {
  CardWrapper,
  ChipContainer,
  CompanyImage,
  CompanyNameStyled,
  DescriptionContainer,
  DetailChip,
  HeaderImage,
  JobSummaryContainer,
  JobSummaryText,
  JobSummaryTextBold,
  JobTitleStyled,
  MoreDetailButton,
  ShowMoreText,
  TitleContainer,
} from "./styles";

export default function SimilarJobCard(props) {
  const {
    index,
    job,
    setInView,
    checkedJob,
    handleSelectJob,
    dialog,
    smallCard,
  } = props;

  const isShort = useMediaQuery("(max-height: 720px)");

  const [hover, setHover] = useState(false);
  const [showFullSkills, setShowFullSkills] = useState(false);
  const [showFullBenefits, setShowFullBenefits] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 0.5, // Adjust this value as needed
  });

  const convertedGptSummary = useMemo(() => {
    if (!job?.gptSummary) return "";

    let regexHex = /&#x([0-9a-fA-F]+);/g;

    let result = job.gptSummary.replace(regexHex, (_, hexValue) => {
      let char = String.fromCharCode(parseInt(hexValue, 16));
      return char;
    });

    return decode(result);
  }, [job?.gptSummary]);

  const truncatedSkills = useMemo(() => {
    if (!job?.skills?.length) return "";

    let skillsString = job.skills.map((skill) => skill.name).join(", ");
    if (skillsString.length <= 70) return skillsString;

    let truncated = skillsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount = job.skills.length - truncated.split(",").length;

    return showFullSkills ? (
      skillsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullSkills(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.skills, showFullSkills]);

  const truncatedBenefits = useMemo(() => {
    if (!job?.company?.benefits?.length) return "";

    let benefitsString = job?.company?.benefits
      ?.map((benefit) => benefit?.title)
      .join(", ");
    if (benefitsString.length <= 70) return benefitsString;

    let truncated = benefitsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount =
      job?.company?.benefits.length - truncated.split(",").length;

    return showFullBenefits ? (
      benefitsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullBenefits(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.company?.benefits, showFullBenefits]);

  const company =
    job?.crossedBorder &&
    job?.crossBorderData?.source_country !=
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()
      ? job?.crossBorderData?.company
      : job?.company;

  const companyLogoUrl = useMemo(() => {
    if (!company?.logo) return null;

    if (typeof company?.logo !== "string") {
      if (company?.logo !== null) {
        return company?.logo?.url;
      }
    }

    return company?.logo;
  }, [company?.logo]);

  const companyLogo = useMemo(() => {
    if (!companyLogoUrl)
      return (
        config?.assetDomain +
        "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png"
      );

    return getCompanyLogoImage(companyLogoUrl);
  }, [companyLogoUrl, company?.logo]);

  //TRACKING RELATED
  function trackImpressionInView() {
    posthog.capture("job_impression", {
      origin: "job_application_popup",
      job_discovery: "similar_jobs",
    });

    sendTrackingEvent({
      event: "CE_job-impression",
      origin: "job_application_popup",
      job_discovery: "similar_jobs",
    });
  }

  const salaryRange = useMemo(() => {
    if (job?.salary === "Undisclosed" || job?.salary === "Undefined")
      return "Undisclosed";

    return formatSalary(job?.salary, job?.crossBorderData);
  }, [job?.salary, job?.crossBorderData]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setInView(index);
      trackImpressionInView(job, index);
    }
  }, [entry?.isIntersecting, index, setInView]);

  return (
    <CardWrapper
      $dialog={dialog}
      $smallCard={smallCard}
      selected={checkedJob(job)}
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleSelectJob(job)}
    >
      {!smallCard && !isShort ? (
        <HeaderImage src={companyLogo} placeholder={!companyLogoUrl} />
      ) : null}

      <TitleContainer>
        <BoxContainer
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"0.25rem"}
        >
          <JobTitleStyled>{job?.title}</JobTitleStyled>
          <CheckboxWrapper>
            <CheckboxStyled
              icon={<UncheckedBox />}
              onClick={() => handleSelectJob(job)}
              checked={checkedJob(job)}
              checkedIcon={
                <CheckedBox>
                  <CheckboxIcon />
                </CheckedBox>
              }
            />
            <CheckboxHover>
              <CheckboxIcon hover={hover} selected={checkedJob(job)} />
            </CheckboxHover>
          </CheckboxWrapper>
        </BoxContainer>
        <BoxContainer alignItems={"center"} gap={"0.5rem"}>
          <CompanyImage src={companyLogo} />
          <CompanyNameStyled>{company?.name}</CompanyNameStyled>
        </BoxContainer>
      </TitleContainer>

      {!smallCard ? (
        <DescriptionContainer>
          <ChipContainer>
            <DetailChip type={"jobType"}>{job?.jobType}</DetailChip>
            <DetailChip type={"state"}>{job?.stateRegion}</DetailChip>
            <DetailChip type={"salary"}>{salaryRange}</DetailChip>
          </ChipContainer>

          <>
            <JobSummaryContainer type={"summary"}>
              <JobSummaryTextBold>AI-Generated Summary:</JobSummaryTextBold>
              {convertedGptSummary !== "" && (
                <JobSummaryText>{convertedGptSummary}</JobSummaryText>
              )}
            </JobSummaryContainer>

            {job?.company?.benefits?.length > 0 && (
              <JobSummaryContainer type={"benefits"}>
                <b>Benefits: </b>
                {truncatedBenefits}
              </JobSummaryContainer>
            )}
            {job?.skills?.length > 0 && (
              <JobSummaryContainer type={"skills"}>
                <b>Skills: </b>
                {truncatedSkills}
              </JobSummaryContainer>
            )}
          </>
        </DescriptionContainer>
      ) : null}

      <MoreDetailButton
        href={"/jobs/" + job?.slug + "?origin=similar_jobs"}
        target={"_blank"}
      >
        View Full Details
      </MoreDetailButton>
    </CardWrapper>
  );
}
