import {
  AppliedCompanyText,
  AppliedJobLink,
  AppliedJobListContainer,
  AppliedJobListWrapper,
  AppliedJobText,
  AppliedJobTextContainer,
} from "./styles";

export default function AppliedJobList(props) {
  const { jobs, jobAlert } = props;

  const jobsList =
    jobs?.map((jobItem) => {
      return jobItem?.job ? jobItem : { job: jobItem };
    }) || [];

  return (
    <AppliedJobListWrapper $jobAlert={jobAlert}>
      <AppliedJobListContainer>
        {jobsList.map((job, index) => {
          return (
            <AppliedJobTextContainer key={index} $jobAlert={jobAlert}>
              <AppliedJobText>{job?.job?.title}, </AppliedJobText>
              <AppliedCompanyText>{job?.job?.company?.name}</AppliedCompanyText>
              {(job?.job?.category === "scraped" ||
                job?.job?.category === "aggregated") &&
                jobAlert && (
                  <AppliedJobLink
                    href={job?.job?.externalJobUrl}
                    target="_blank"
                  >
                    {" "}
                    [Link to Website]
                  </AppliedJobLink>
                )}
            </AppliedJobTextContainer>
          );
        })}
      </AppliedJobListContainer>
    </AppliedJobListWrapper>
  );
}
