import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import Collapse from "@mui/material/Collapse";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

export const CloseLink = styling("div")({
  color: Color.hiredlyPurple,
  fontWeight: "500",
  fontSize: "0.75rem",
  lineHeight: "20px",
  textDecoration: "underline",
  textAlign: "center",

  "&:hover": {
    cursor: "pointer",
  },
});

export const SecondaryWrapper = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  maxWidth: "100%",
  width: "100%",
  maxHeight: "514px",

  transition: "all 0.1s ease-in-out",

  ...(!props?.dialog && {
    maxWidth: "1440px",
    padding: "0.75rem 6.25rem",
  }),

  ...(props?.jobAlert && {
    backgroundColor: Color.lightGrey,
    borderBottom: `1px solid ${Color.secondaryGrey}`,

    ...(props?.open && {
      backgroundColor: Color.white,
    }),
  }),

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 1280px)": {
    padding: "0.75rem 1.5rem",
  },

  "@media (max-width: 640px)": {
    padding: "0",
  },
}));

export const SecondaryHeaderContainer = styling("div")((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: "0",

  width: "100%",

  padding: "0",
  transition: "all 0.1s ease-in-out",

  ...(props?.dialog && {
    padding: "0.75rem 1.5rem 0",

    ...(!props?.open && {
      backgroundColor: Color.lightGrey,
      padding: "0.75rem 1.5rem",
    }),
  }),

  "@media (max-width: 640px)": {
    padding: "0.5rem 1rem",
  },
}));

export const SecondaryListContainer = styling("div")({
  borderTop: `1px solid ${Color.grey}`,
  maxHeight: "474px",
  height: "100%",
  overflowY: "hidden",
});

export const CollapsibleContainer = styling(Collapse)((props) => ({
  "& .MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    padding: "0.5rem 1.5rem 0.75rem",

    "@media (max-width: 640px)": {
      padding: "0 1rem 0.75rem",
    },
  },
}));

export const ArrowDownIcon = styling(ChevronDownIcon)((props) => ({
  color: "rgba(0, 0, 0, 0.60)",
  height: "20px",
  width: "20px",

  transform: props?.open ? "rotate(180deg)" : "rotate(0deg)",
}));
